import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import moment from "moment";

export default createStore({
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
	state: {
		account: {},
		userLogin: false,
		user_data: {},
		basket: [],
		pricing: false,
		basket_summary: {
			case_title: "",
			case_ref: "",
			case_client: "",
			required_by: "",
		},
		autofill: [],
	},
	mutations: {
		setAccount(state, data) {
			state.autofill = [];
			state.account = data;
		},
		setOrder(state, data) {
			if (data == false) {
				state.basket = [];
				state.autofill = [];
				state.basket_summary = {
					case_title: "",
					case_ref: "",
					case_client: "",
					required_by: "",
				};
			} else {
				state.basket = data;
			}
		},
		setPricingTable(state, table) {
			state.account.pricing_table = table;
		},
		setPricing(state, data) {
			state.pricing = data;
		},
		setState(state, bool) {
			state.autofill = [];
			state.userLogin = bool;
		},
		setOrderSummary(state, bool) {
			state.basket_summary = bool;
		},
		setAutoFill(state, data) {
			state.autofill.push(data);

			let autofillData = state.autofill;
			let stringArray = autofillData.map(JSON.stringify);
			let uniqueStringArray = new Set(stringArray);

			state.autofill = Array.from(uniqueStringArray, JSON.parse);
		},
	},
	getters: {
		getAccount: (state) => {
			return state.account;
		},
		getAutofill: (state) => {
			return state.autofill;
		},
		getState: (state) => {
			return state.userLogin;
		},
		getUserData: (state) => {
			return state.user_data;
		},
		getBasket: (state) => {
			return state.basket;
		},
		getPricingTable: (state) => {
			return state.pricing;
		},
		getBasketSummary: (state) => {
			return state.basket_summary;
		},
		getBasketMeta: (state) => {
			let meta = {
				count: 0,
				total: 0.0,
				sub_total: 0.0,
				vat: 0.0,
			};

			for (let x = 0; x < state.basket.length; x++) {
				if (state.basket[x].is_bundle == 0) {
					for (let y = 0; y < state.basket[x].data.length; y++) {
						meta.count++;
						if (state.basket[x].product_id == 15) {
							let rod = {},
								temp = {};
							for (let x = 0; x < state.pricing.length; x++) {
								if (state.pricing[x].product_id == 15) {
									rod = {
										price: parseFloat(state.pricing[x].total),
										vat: parseFloat(state.pricing[x].vat),
										pre_price: parseFloat(state.pricing[x].total_rep),
										pre_vat: parseFloat(state.pricing[x].vat_rep),
									};
								}
							}
							temp = {
								from: 0,
								to: 0,
							};
							for (let z = 0; z < state.basket[x].data[y].length; z++) {
								if (state.basket[x].data[y][z].name == "date_from") {
									temp.from = state.basket[x].data[y][z].value;
								}

								if (state.basket[x].data[y][z].name == "date_to") {
									temp.to = state.basket[x].data[y][z].value;
								}
							}

							let dataX = {};
							let pre = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);
							if (moment(temp.to, "YYYY/MM/DD") >= 1970) {
								pre = Math.ceil(moment(moment("1969/12/31", "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);
							}
							let post = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);
							if (moment(temp.from, "YYYY/MM/DD") <= 1969) {
								post = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment("1970/01/01", "YYYY/MM/DD"), "years", true) / 10);
							}

							if (pre <= 0) pre = 0;
							if (post <= 0) post = 0;
							dataX.pre = pre;
							dataX.post = post;
							let yearsDiff = dataX;
							meta.total += yearsDiff.pre * rod.pre_price;
							meta.total += yearsDiff.post * rod.price;
							meta.vat += yearsDiff.pre * rod.pre_vat;
							meta.vat += yearsDiff.post * rod.vat;
						} else {
							if (y == 0) {
								meta.total += state.basket[x].price.total;
								meta.vat += state.basket[x].price.vat;
							} else {
								meta.total += state.basket[x].price.total_rep;
								meta.vat += state.basket[x].price.vat_rep;
							}
						}
					}
				} else {
					meta.count++;
					if (state.basket[x].name == "ROD") {
						let rod = {},
							temp = {};
						for (let x = 0; x < state.pricing.length; x++) {
							if (state.pricing[x].product_id == 34) {
								for (let y = 0; y < state.pricing[x].fields.data.length; y++) {
									if (state.pricing[x].fields.data[y].id == 15)
										rod = {
											price: parseFloat(state.pricing[x].fields.data[y].total),
											vat: parseFloat(state.pricing[x].fields.data[y].vat),
											pre_price: parseFloat(state.pricing[x].fields.data[y].deeds_total),
											pre_vat: parseFloat(state.pricing[x].fields.data[y].deeds_vat),
											rep: parseFloat(state.pricing[x].repetition),
										};
								}
							}
						}
						let dataX = {};
						let preTemp,
							postTemp,
							pre = 0,
							post = 0;
						for (let xx = 0; xx < state.basket[x].data.length; xx++) {
							temp = {
								from: 0,
								to: 0,
							};
							for (let y = 0; y < state.basket[x].data[xx].data.length; y++) {
								if (state.basket[x].data[xx].product_id == 15) {
									for (let z = 0; z < state.basket[x].data[xx].data[y].length; z++) {
										if (state.basket[x].data[xx].data[y][z].name == "date_from") {
											temp.from = state.basket[x].data[xx].data[y][z].value;
										}

										if (state.basket[x].data[xx].data[y][z].name == "date_to") {
											temp.to = state.basket[x].data[xx].data[y][z].value;
										}
									}
									preTemp = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);
									postTemp = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);

									if (moment(temp.to, "YYYY/MM/DD") >= 1970) {
										preTemp = Math.ceil(moment(moment("1969/12/31", "YYYY/MM/DD")).diff(moment(temp.from, "YYYY/MM/DD"), "years", true) / 10);
										if (temp.to == temp.from) preTemp = 1;
									}

									if (moment(temp.from, "YYYY/MM/DD") <= 1969) {
										postTemp = Math.ceil(moment(moment(temp.to, "YYYY/MM/DD")).diff(moment("1970/01/01", "YYYY/MM/DD"), "years", true) / 10);
										if (temp.to == temp.from) postTemp = 1;
									}
									if (preTemp <= 0) preTemp = 0;
									if (postTemp <= 0) postTemp = 0;
									pre += preTemp;
									post += postTemp;
								}
							}

							dataX.pre = pre;
							dataX.post = post;
						}
						let yearsDiff = dataX;

						if (yearsDiff.post >= rod.rep) yearsDiff.post = yearsDiff.post - rod.rep;
						else yearsDiff.post = 0;

						meta.total += yearsDiff.pre * rod.pre_price;
						meta.total += yearsDiff.post * rod.price;
						meta.vat += yearsDiff.pre * rod.pre_vat;
						meta.vat += yearsDiff.post * rod.vat;
						meta.total += state.basket[x].total;
						meta.vat += state.basket[x].vat;
					} else {
						meta.total += state.basket[x].total;
						meta.vat += state.basket[x].vat;
						let bundle_item, max_rep, temp_val;

						for (let bx = 0; bx < state.basket[x].data.length; bx++) {
							bundle_item = state.basket[x].data[bx];

							for (let xp = 0; xp < state.pricing.length; xp++) {
								if (state.pricing[xp].product_bundle) {
									if (state.pricing[xp].product_name == state.basket[x].name) {
										for (let y = 0; y < state.pricing[xp].fields.data.length; y++) {
											if (state.pricing[xp].fields.data[y].label.split("] -")[1] == bundle_item.name) {
												bundle_item.extra = state.pricing[xp].fields.data[y];
											}
										}
									}
								}
							}

							if (bundle_item.extra) {
								max_rep = parseInt(bundle_item.extra.repetition_bundle) + 1;
								if (bundle_item.data.length > max_rep) {
									temp_val = bundle_item.data.length - max_rep;

									meta.total += temp_val * parseFloat(bundle_item.extra.total);
									meta.vat += temp_val * parseFloat(bundle_item.extra.vat);
								}
							}
						}
					}
				}
			}

			meta.sub_total = meta.total - meta.vat;

			return meta;
		},
	},
});
